export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${hasBg ? "gray-light-bg" : "background-shape-right"
            } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    O melhor custo benefício, compare!
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md position-relative">
                <div className="card text-center popular-price single-pricing-pack p-5 h-100">
                  <h5 className="mb-2">Imobiliárias e Corretores</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Reduza o tempo da administração em até 80%!</li>
                      <li>Gestão de Locações, Boletos, Notas Fiscais...</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$ <span className="price font-weight-bolder">583,25</span>
                        <span className="font-weight-bolder" style={{ fontSize: '0.5em' }}>/ mês</span>
                      </div>
                    </div>
                    <a href="https://app.rentzapp.com.br/auth/novaconta/?pgclk=49z59U6" className="btn solid-btn" target="_blank" rel="noreferrer">
                      Saiba Mais
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md position-relative">
                <div className="card text-center popular-price single-pricing-pack p-5 h-100">
                  <h5 className="mb-2">Portal para Imóveis</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Portal para seus Imóveis totalmente integrado e de fácil gestão!</li>
                      <li>Fotos e Vídeos, Propostas...</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$ <span className="price font-weight-bolder">167,50</span>
                        <span className="font-weight-bolder" style={{ fontSize: '0.5em' }}>/ mês</span>
                      </div>
                    </div>
                    <a href="https://app.rentzapp.com.br/auth/novaconta/?pgclk=z54U6U6" className="btn outline-btn" target="_blank" rel="noreferrer">
                      Saiba Mais
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md position-relative">
                <div className="card text-center popular-price single-pricing-pack p-5 h-100">
                  <h5 className="mb-2">Síndico de Condomínios</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Páre de correr atrás dos pagamentos, nossa plataforma automatiza tudo para você!</li>
                      <li>e mais...</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$ <span className="price font-weight-bolder">316,25</span>
                        <span className="font-weight-bolder" style={{ fontSize: '0.5em' }}>/ mês</span>
                      </div>
                    </div>
                    <a href="https://app.rentzapp.com.br/auth/novaconta/?pgclk=k0z5bU6" className="btn outline-btn" target="_blank" rel="noreferrer">
                      Saiba Mais
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md position-relative">
                <div className="card text-center popular-price single-pricing-pack p-5 h-100">
                  <h5 className="mb-2">Gestão de Honorários</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Esqueças as planilhas, mostre eficiência em suas cobranças recorrentes tudo no automático!</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        R$ <span className="price font-weight-bolder">177,50</span>
                        <span className="font-weight-bolder" style={{ fontSize: '0.5em' }}>/ mês</span>
                      </div>
                    </div>
                    <a href="https://app.rentzapp.com.br/auth/novaconta/?pgclk=N47181" className="btn outline-btn" target="_blank" rel="noreferrer">
                      Saiba Mais
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                Para mais informações
                <a href="#footer" className="color-secondary">
                  {" "}
                  utilize nossos contatos{" "}
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
